import React from 'react'
import { Layout } from '@components/LayoutEN'
import { SEO } from '@components/SEO'
import MyTabs from '@components/Tab'
import { navigate } from 'gatsby'

import {
  Hero,
  HeroTitle,
  Benefits,
  Program,
  ProgramDes,
} from '../screens/JoinUsEN'

const JoinUs = () => {
  if (typeof window !== 'undefined' && window.location.pathname === '/joinUs') {
    navigate('/es/joinUs')
  }

  return (
    <Layout>
      <SEO title="Join Us" />
      <HeroTitle
        subtitle="Job Offer"
        titleProgram="We have the most exciting Programs that you would like to be apart of it."
        email="info@aibrok.com.mx"
        suggestion="Send us your resume to "
      />
      <MyTabs
        labelTab2="Financial Advisor"
        labelTab="Wealth Manager"
        tabContent={
          <>
            <Hero titleProgram="Wealth Manager" joinUs="Join Us" />
            <ProgramDes
              descriptionOne=" Would you like to work in the Fintech sector, working in one of
               those that is experiencing the greatest growth nationally and
               internationally?"
              subDescriptionOne="At Aibrok, a leading company in the Fintech sector, we are
               looking for Collaborating Agents who provide knowledge, skills
               and experience in customer management. Committed, proactive
               people who want to learn and add value."
              descriptionTwo="  We are more than 175 people in the entity, with a network of 25
              
                offices."
              subDescriptionTwo=" If you want to develop your professional career in the
               technological financial sector, this is your opportunity."
            />
            <Benefits />
          </>
        }
        tabContent2={
          <>
            <Hero titleProgram="Financial Advisor" joinUs="Join Us" />
            <ProgramDes
              descriptionOne=" Do you want to develop your career in the Fintech sector,
               working in one of those that is experiencing the greatest growth
               at the national level?"
              descriptionTwo=" At Aibrok we offer you the opportunity to build a successful
               career, obtaining a global vision of the business with a
               training and development program and a personalized career plan
               as an Account Manager!"
            />
            <Program />
          </>
        }
      />
    </Layout>
  )
}

export default JoinUs
